export  const kids = [
    {  image: './assests/Garments/5.png' },
    {  image: './assests/Garments/6.png' },
    {  image: './assests/Garments/7.png' },
    {  image: './assests/Garments/8.png' },
    {  image: './assests/Garments/9.png' },
    {  image: './assests/Garments/10.png' },
    {  image: './assests/Garments/16.png' },
    {  image: './assests/Garments/12.png' },
    {  image: './assests/Garments/13.png' },
    {  image: './assests/Garments/14.png' },
    {  image: './assests/Garments/15.png' },
    {  image: './assests/Garments/11.png' },
    {  image: './assests/Garments/17.png' },
    {  image: './assests/Garments/Nightwear,.png' },
    {  image: './assests/Garments/ready-to-wear.png' },
    {  image: './assests/Garments/rompers.png' },
    {  image: './assests/Garments/Shorts.png' },
    {  image: './assests/Garments/18.png' },
   
  ];

  export   const men=[
    {  image: './assests/Garments/Men/1.png' },
    {  image: './assests/Garments/Men/2.png' },
    {  image: './assests/Garments/Men/4.png' },
    {  image: './assests/Garments/Men/5.png' },
    {  image: './assests/Garments/Men/7.png' },
    {  image: './assests/Garments/Men/3.png' },
    {  image: './assests/Garments/Men/8.png' },
    {  image: './assests/Garments/Men/9.png' },
    {  image: './assests/Garments/Men/6.png' },
    {  image: './assests/Garments/Men/10.png' },
    
  ];

  export  const women=[
    {  image: './assests/Garments/Women/c1.png' },
    {  image: './assests/Garments/Women/c2.png' },
    {  image: './assests/Garments/Women/c3.png' },
    {  image: './assests/Garments/Women/c4.png' },
    {  image: './assests/Garments/Women/c5.png' },
    {  image: './assests/Garments/Women/c7.png' },
    {  image: './assests/Garments/Women/c8.png' },
    {  image: './assests/Garments/Women/c9.png' },
    {  image: './assests/Garments/Women/c10.png' },
    {  image: './assests/Garments/Women/c11.png' },
    {  image: './assests/Garments/Women/c12.png' },
    {  image: './assests/Garments/Women/c13.png' },
    {  image: './assests/Garments/Women/c14.png' },
    {  image: './assests/Garments/Women/c15.png' },
    {  image: './assests/Garments/Women/c16.png' },
    {  image: './assests/Garments/Women/c17.png' },
    {  image: './assests/Garments/Women/c18.png' },
    {  image: './assests/Garments/Women/c19.png' },
    {  image: './assests/Garments/Women/c20.png' },
    {  image: './assests/Garments/Women/trouser.png' },
    {  image: './assests/Garments/Women/c21.png' },
    {  image: './assests/Garments/Women/c22.png' },
    {  image: './assests/Garments/Women/c23.png' },
    {  image: './assests/Garments/Women/c24.png' },
    {  image: './assests/Garments/Women/c25.png' },
    {  image: './assests/Garments/Women/c6.png' },
   
  ]

  export   const cosmeticsProduct = [
    
    {  image: './assests/cosmetics/CC Cream.png' },
    {  image: './assests/cosmetics/Eye shadow.png' },
    {  image: './assests/cosmetics/Eyeliner.png' },
    {  image: './assests/cosmetics/Lip balm.png' },
    {  image: './assests/cosmetics/Lipstick.png' },
    {  image: './assests/cosmetics/Mascara.png' },
    {  image: './assests/cosmetics/Moisturizer.png' },
    {  image: './assests/cosmetics/Primer.png' },
    {  image: './assests/cosmetics/Setting Spray.png' },
    {  image: './assests/cosmetics/Foundation1.png' },
    {  image: './assests/cosmetics/Blush.png' },
    {  image: './assests/cosmetics/Brownzer.png' },
    {  image: './assests/cosmetics/Concealer.png' },
    {  image: './assests/cosmetics/Colorcorrector.png' },
    {  image: './assests/cosmetics/Compact.png' },
    {  image: './assests/cosmetics/Highlighter 2.png' },
    {  image: './assests/cosmetics/Face powder.png' },
    {  image: './assests/cosmetics/Foundation.png' },
    {  image: './assests/cosmetics/Gel eyelinear.png' },
    {  image: './assests/cosmetics/Highlighter.png' },
    {  image: './assests/cosmetics/Liner1.png' },
    {  image: './assests/cosmetics/Lip gloss.png' },
    {  image: './assests/cosmetics/Scrab.png' },
    { image: './assests/cosmetics/p1.png' },
    
  ];

  export   const disposableProduct = [
    { image: './assests/Disposable/19.png' },
    { image: './assests/Disposable/20.png' },
    { image: './assests/Disposable/21.png' },
    { image: './assests/Disposable/22.png' },
    { image: './assests/Disposable/23.png' },
    { image: './assests/Disposable/24.png' },
    { image: './assests/Disposable/3.png' },
    { image: './assests/Disposable/7.png' },
    { image: './assests/Disposable/8.png' },
    { image: './assests/Disposable/9.png' },
    { image: './assests/Disposable/10.png' },
    { image: './assests/Disposable/11.png' },
    { image: './assests/Disposable/12.png' },
    { image: './assests/Disposable/13.png' },
    { image: './assests/Disposable/14.png' },
    { image: './assests/Disposable/15.png' },
    { image: './assests/Disposable/16.png' },
    { image: './assests/Disposable/17.png' },
    { image: './assests/Disposable/18.png' },
    { image: './assests/Disposable/Glass (2).png' },
    { image: './assests/Disposable/Glass.png' },
    { image: './assests/Disposable/Ice.png' },
    { image: './assests/Disposable/Plates.png' },
    { image: './assests/Disposable/Tea.png' },
  ];

  export  const stationaryProduct = [
    {  image: './assests/Stationery/22.png' },
    {  image: './assests/Stationery/board pens.png' },
    {  image: './assests/Stationery/Business Stationery.png' },
    {  image: './assests/Stationery/Calculator.png' },
    {  image: './assests/Stationery/Clip.png' },
    {  image: './assests/Stationery/colored folder.png' },
    {  image: './assests/Stationery/Cuter.png' },
    {  image: './assests/Stationery/Envelopes.png' },
    {  image: './assests/Stationery/Erasers.png' },
    {  image: './assests/Stationery/Greeting cards.png' },
    {  image: './assests/Stationery/Highlighters.png' },
    {  image: './assests/Stationery/Invitations.png' },
    {  image: './assests/Stationery/Notecards.png' },
    {  image: './assests/Stationery/Notepads.png' },
    {  image: './assests/Stationery/Pencils.png' },
    {  image: './assests/Stationery/Pens.png' },
    {  image: './assests/Stationery/pin.png' },
    {  image: './assests/Stationery/Personalized stationery.png' },
    {  image: './assests/Stationery/Postcards.png' },
    {  image: './assests/Stationery/Rubber.png' },
    {  image: './assests/Stationery/Stapler.png' },
    {  image: './assests/Stationery/sticky notes.png' },
    {  image: './assests/Stationery/Taps.png' },
    {  image: './assests/Stationery/Writing Paper.png' },
   
  ];

  export  const paperProduct = [
    {  image: './assests/Paper/Carbon paper.png' },
    {  image: './assests/Paper/Carbonless.png' },
    {  image: './assests/Paper/Chalk Paper.png' },
    {  image: './assests/Paper/Construction paper.png' },
    {  image: './assests/Paper/Cotton paper.png' },
    {  image: './assests/Paper/Cover Stock.png' },
    {  image: './assests/Paper/Graph paper.png' },
    {  image: './assests/Paper/Kraft paper.png' },
    {  image: './assests/Paper/Printing paper.png' },
    {  image: './assests/Paper/Rice paper.png' },
    {  image: './assests/Paper/Stickers.png' },
    {  image: './assests/Paper/Tag Paper.png' },
    {  image: './assests/Paper/Tissue paper.png' },
    {  image: './assests/Paper/Tracing paper.png' },
    {  image: './assests/Paper/Waxed paper.png' },
    {  image: './assests/Paper/17.png' },
    
  ];
  export  const cos =[
    {  image: './assests/cosmetics/p1.png' },
    {  image: './assests/cosmetics/CC Cream.png' },
    {  image: './assests/cosmetics/Eye shadow.png' },
    {  image: './assests/cosmetics/Eyeliner.png' },
    {  image: './assests/cosmetics/Lip gloss.png' },
    {  image: './assests/cosmetics/Scrab.png' },
    {  image: './assests/cosmetics/Brownzer.png' },
    {  image: './assests/cosmetics/Concealer.png' },
  ];
export  const paper=[

    {  image: './assests/Paper/Carbon paper.png' },
    {  image: './assests/Paper/Carbonless.png' },
    {  image: './assests/Paper/Chalk Paper.png' },
    {  image: './assests/Paper/Construction paper.png' },
    {  image: './assests/Paper/Cotton paper.png' },
    {  image: './assests/Paper/Cover Stock.png' },
    {  image: './assests/Paper/Graph paper.png' },
    {  image: './assests/Paper/Kraft paper.png' },
  ];
export  const stationary= [
    {  image: './assests/Stationery/Calculator.png' },
    {  image: './assests/Stationery/Clip.png' },
    {  image: './assests/Stationery/colored folder.png' },
    {  image: './assests/Stationery/Cuter.png' },
    {  image: './assests/Stationery/Greeting cards.png' },
    {  image: './assests/Stationery/Highlighters.png' },
    {  image: './assests/Stationery/Invitations.png' },
    {  image: './assests/Stationery/Notecards.png' },
  ];
 export const disposable = [
    { image: './assests/Disposable/19.png' },
    { image: './assests/Disposable/20.png' },
    { image: './assests/Disposable/Tea.png' },
    { image: './assests/Disposable/14.png' },
    { image: './assests/Disposable/16.png' },
    { image: './assests/Disposable/Ice.png' },
    { image: './assests/Disposable/15.png' },
    { image: './assests/Disposable/3.png' }, 
  ];
export  const garment =[
  {  image: './assests/Garments/5.png' },
  {  image: './assests/Garments/15.png' },
  {  image: './assests/Garments/rompers.png' },
  {  image: './assests/Garments/10.png' },
  {  image: './assests/Garments/Men/1.png' },
  {  image: './assests/Garments/Men/9.png' },
  {  image: './assests/Garments/Men/5.png' },
  {  image: './assests/Garments/Men/4.png' },
  {  image: './assests/Garments/Women/c4.png' },
  {  image: './assests/Garments/Women/c2.png' },
  {  image: './assests/Garments/Women/c20.png' },
  {  image: './assests/Garments/Women/c25.png' },
];