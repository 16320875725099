import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <div>
      
       <footer className="text-center text-lg-start  text-muted " style={{backgroundColor
    :"rgb(144, 19, 117)"}}>
    {/* Section: Social media */}
    <section className="d-flex justify-content-end justify-content-lg-between p-4 border-bottom text-light">
  
  <form className="container-fluid d-flex  justify-content-end">
  <Link to="https://web.facebook.com/a3hmirglobalcorporation?mibextid=ZbWKwL&_rdc=1&_rdr" target="_blank"> <i class="fa-brands fa-facebook-f p-2 me-2 fs-5" id='icon'></i></Link>
<Link to="https://web.facebook.com/a3hmirglobalcorporation?mibextid=ZbWKwL&_rdc=1&_rdr" target="_blank"> <i class="fa-brands fa-x-twitter p-2 me-2 fs-5" id='icon'></i></Link>
<Link to="https://www.instagram.com/mirglobalcorporations/" target="_blank"> <i class="fa-brands fa-instagram p-2 me-2 fs-5" id='icon'></i></Link>
<Link to="https://www.linkedin.com/company/mirglobalcorporation/" target="_blank"> <i class="fa-brands fa-linkedin-in  fs-5 p-2" id='icon'></i></Link> 


  </form>

    </section>
     {/* Section: Social media */}
    {/* Section: Links  */} 
    <section className>
      <div className="container text-center text-md-start mt-5 text-light">
         {/* Grid row */} 
        <div className="row mt-3">
           {/* Grid column */} 
          <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
             {/* Content */} 
            <h5 className="text-uppercase fw-bold mb-4 ">
              <i className=" me-3 " />  A3HMIR
            <br></br>
            <h6>GLOBAL CORPORATION</h6>
            </h5>
            <p>
            The aim of global corporations is to maximize shareholder value through efficient operations and international market expansion.
            </p>
          </div>
           {/* Grid column */}
          {/* Grid column */} 
          <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 " >
             {/* Links */} 
            <h6 className="text-uppercase fw-bold mb-4">
             Courses
            </h6>
            <p>
              <Link  to ="/" className="text-reset"  style={{ textDecoration: 'none',color:'#fff' }}>Home</Link>
            </p>
            <p>
              <Link to ="/about" className="text-reset"  style={{ textDecoration: 'none',color:'#fff' }}>About</Link>
            </p>
          
            <p>
              <Link to ="/contact" className="text-reset"  style={{ textDecoration: 'none',color:'#fff' }}>Contact Us</Link>
            </p>
           
          </div>
           {/* Grid column */}
          {/* Grid column */} 
          <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
             {/* Links */} 
            <h6 className="text-uppercase fw-bold mb-4">
              Useful links
            </h6>
            <p>
              <Link to="/clothes" className="text-reset"  style={{ textDecoration: 'none',color:'#fff' }}>Products</Link>
            </p>
            <p>
              <Link to="/contact" className="text-reset"  style={{ textDecoration: 'none',color:'#fff' }}>Contact Us</Link>
            </p>
           
          </div>
           {/* Grid column */} 
           {/* Grid column */} 
          <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
        {/* Links */} 
            <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
            <p><i className="fas fa-home " /> Dubai, United Arab Emirates</p>
           <p > <Link style={{ textDecoration: 'none', color:'#fff'}}>
              <i className="fas fa-envelope me-1 text-light" />hi@a3hmirglobalcorporation.com
            </Link></p>
            <Link style={{ textDecoration: 'none',color:'#fff' }}><i className="fas fa-phone  text-light" /> +971-562388456</Link>
            
          </div>
           {/* Grid column */} 
        </div>
         {/* Grid row */} 
      </div>
    </section>
    <div className="text-center p-4 text-light" style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
      © 2024 Copyright A3HMIR Global Corporation
    </div>
  </footer>

    </div>
  )
}

export default Footer
